const debuggingDefer = false;

function defer(method) {
    if (typeof method != 'function') {
        console.log('method is not a function')
        console.log(method)
    } else if (window.jQuery) {
        $(document).ready(method());
        if (debuggingDefer)
            console.log('completed deferred method!');
    } else {
        setTimeout(function() { defer(method) }, 50);
    }
}

function setUpScrollBottom() {
$.fn.scrollBottom = function(scroll){
    // Scroll the element scroll pixels from its bottom, or, return how far it is scrolled from its bottom.
    var elem = $(this);
    if(typeof scroll === 'number'){
        elem.scrollTop(elem.get(0).scrollHeight - elem.height() - scroll);
        return elem.get(0).scrollHeight - elem.height() - scroll;
    } else {
        return elem.get(0).scrollHeight - elem.height() - elem.scrollTop();
    }
};
}

defer(setUpScrollBottom);


if (debuggingDefer)
    console.log('deferred setUpScrollBottom');

(function() {
    if (debuggingDefer) {
        console.log('in scrollbottom.js');
        console.log(this);
        console.log('window');
        console.log(window);
        console.log('if we can access the window, seems we could move cable.js!');
    }
}).call(this);

export default defer
