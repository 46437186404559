/*            <%= button_tag 'Accept', type: :button, id: "accept-cookies", onclick: 'console.log("accepting...");document.cookie="' + accept_cookies_key + ' = 1;";$("#cookie-notice-footer").hide(300);return false;', class: "btn btn-success btn-sm" %>
            <%= button_tag 'No thanks', type: :button, id: "reject-cookies", onclick: 'console.log("rejecting...");document.cookie="' + accept_cookies_key + ' = 0;";$("#cookie-notice-footer").hide(300);return false;', class: "btn btn-danger btn-sm" %>
*/

import defer from './scrollbottom.js';

// if (false) {
// console.log('cookieNotice.js');

// var tries = 0;

// function applyHelpers() {
//   const $acceptCookies = $('#accept-cookies');
//   const $rejectCookies = $('#reject-cookies');

//   console.log('accept, reject', $acceptCookies, $rejectCookies);

//   if ($acceptCookies.length && $rejectCookies.length) {
//     console.log('found both!  continueing.');
//   } else {
//     if (tries < 3) {
//       tries = tries + 1;
//       setTimeout(function(){
//         defer(applyHelpers);
//       }, 50);
//     } else {
//       console.log('did tries, giving up', tries);
//     }
//     return false;
//   }

//   $acceptCookies.on('click', function(event) {
//     //console.log("accepting...");
//     document.cookie="' + accept_cookies_key + ' = 1;";
//     $("#cookie-notice-footer").hide(300);
//     return false;
//   });

//   $rejectCookies.on('click', function(event) {
//     //console.log("rejecting...");
//     document.cookie="' + accept_cookies_key + ' = 0;";
//     $("#cookie-notice-footer").hide(300);
//     return false;
//   });
// }


// defer( applyHelpers );
// console.log('end cookieNotice.js');
// }
// document.cookie = "accept_nonessential_cookies=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
