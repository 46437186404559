/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

import 'core-js/stable'
import 'regenerator-runtime/runtime'

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

const debuggingImports = false;

if (debuggingImports)
    console.log('Hello World from Webpacker')

import '../shared/scrollbottom.js';
import defer from '../shared/scrollbottom.js';

defer( () => {
    if (debuggingImports)
        console.log('imported scrollbottom')
});

if (debuggingImports)
    console.log('imported scrollbottom');

require('shared/cookieNotice.js');

/* this probably will not help...  from https://www.npmjs.com/package/jstz
export function findTimeZone() {
  const oldIntl = window.Intl
  try {
    window.Intl = undefined
    const tz = jstz.determine().name()
    window.Intl = oldIntl
    return tz
  } catch (e) {
    // sometimes (on android) you can't override intl
    return jstz.determine().name()
  }
}

console.log('exported findTimeZone')
*/
